<template>
    <div id="business-detail">
        <div class="container ext-md" v-html="content"></div>
    </div>
</template>

<script>
export default {
    name: 'Detail',
    mounted() {
        const $ = this.$jq;
        let container = $('.ext-md');

        container
            .find('h2')
            .map(function(i, el) {
                let p = $(el).nextUntil('h2');
                p.find('img[alt="right"]')
                    .parent()
                    .addClass('col-md-6');
                return p.add(el);
            })
            .wrap('<section class="row">');

        container.find('.row').each(function(i, el) {
            $(el)
                .children(':not(.col-md-6)')
                .wrapAll('<div class="col-md-6">');
        });
    },
    computed: {
        content() {
            let path = this.$route.query.path;
            return require('@a/' + path);
        }
    }
};
</script>

<style lang="less">
@import '../../style/mixin';

#business-detail {
    .p-y(2rem);
    overflow: hidden;

    .ext-md {
        > h1 {
            margin-bottom: 3rem;
            padding-bottom: 0.5rem;
            border-bottom: 2px solid #eee;
            width: 10rem;
        }

        h2 {
            margin-bottom: 1.5rem;
        }

        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 1rem;
        }

        .row {
            margin-bottom: 6rem;
            img {
                width: 100%;
                border-radius: 0.5rem;
                box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
            }
        }
    }
}
</style>