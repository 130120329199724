const news = [
    {
        title: '集团新闻',
        data: []
    },
    {
        title: '公告',
        data: []
    },
    {
        title: '行业资讯',
        data: []
    }
];

export default {
    news,
    activeTab: 0
};